import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import { AppVersionService } from '@core/http/app-version/app-version.service';
import { IAppVersionInfo } from '@core/http/app-version/interfaces/app-info.interface';
import {environment} from "@environments/environment";

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.sass']
})
export class ModalInfoComponent implements OnInit {

  public versionClient: string = environment.VERSION_CLIENT;
  public versionServer: string = environment.VERSION_SERVER;
  constructor(
    public dialogRef: MatDialogRef<ModalInfoComponent>,
    private readonly appInfoService: AppVersionService
  ) { }

  public appVersionInfo!: IAppVersionInfo

  ngOnInit(): void {
    this.appInfoService.getAppVersionInfo().subscribe(data => {
      if(!this.isEmpty(data))
        this.appVersionInfo = data
    })
  }

  isEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }
}
