import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  // TODO: [Estefanía Aguas] Cambiar esto a null
  private token: string | null = "tracr";
  private entity: string = environment.ENTITY;

  setToken(token: string): void {
    this.token = token;
  }

  getToken(): string | null {
    if(sessionStorage.getItem('tracr')){
      this.token = sessionStorage.getItem('tracr');
    }
    return this.token;
  }

  clearToken(): void {
    this.token = null;
  }

  getHeaders(): { [key: string]: string } {
    if (this.token) {
      return { Authorization: `Bearer ${this.getToken()}` };
    }
    return {};
  }

  public userGrantsInOrganization():string[]{
    if(!this.token) return [];
    try {
      const tokenData:any = jwt_decode(this.token);
      const userGrants = tokenData.user_grants;
      if (!userGrants) {
        return [];
      }
      return userGrants[this.entity]
    } catch (error) {
      return [];
    }
  }

  public tokenHasGrant(grants: Array<string>):boolean{
    if (!this.token) return false;
    try {
      const tokenData:any = jwt_decode(this.token);
      const userGrants = tokenData.user_grants;
      if (!userGrants) {
        return false;
      }
      const userGrantsInOrganization:string[] = userGrants[this.entity]
      if (!userGrantsInOrganization) {
        return false;
      }
      return grants.every((grant: string) => userGrantsInOrganization.includes(grant));
    } catch (error) {
      return false;
    }
  }

  public tokenHasAnyGrant(grants: Array<string>):boolean{
    if (!this.token) return false;
    try {
      const tokenData:any = jwt_decode(this.token);
      const userGrants = tokenData.user_grants;
      if (!userGrants) {
        return false;
      }
      const userGrantsInOrganization:string[] = userGrants[this.entity]
      if (!userGrantsInOrganization) {
        return false;
      }
      return grants.some((grant: string) => userGrantsInOrganization.includes(grant));
    } catch (error) {
      return false;
    }
  }
}
