import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "@environments/environment";
import { Observable } from 'rxjs';
import { IAppVersionInfo } from './interfaces/app-info.interface';

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {
  private readonly ACTUATOR_URL: string = environment.ACTUATOR_URL;

  constructor(
    private readonly HttpClient: HttpClient
  ) { }

  public getAppVersionInfo(): Observable<IAppVersionInfo> {
    return this.HttpClient.get<IAppVersionInfo>(`${this.ACTUATOR_URL}`)
  }
}
