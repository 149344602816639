<h2 mat-dialog-title class="title__dialog">
  ACERCA DE
  <img src="../../../../assets/icons/x_blanca.svg" (click)="dialogRef.close()" class="close-modal" alt="">
</h2>
<div class="content__dialog">
  <mat-dialog-content>
    <img style="display: inline-block; width: 70%; text-align: center;"
         src="assets/images/logo_main_blue_alt.png">
    <p>TRACES - SISTEMA DE EVIDENCIA DE ACCIDENTES DE TRÁNSITO, es una marca registrada por GCA Technologies Colombia</p>
    <p>Todos los derechos reservados · soportetraces@gcatech.net</p>
    <p *ngIf="appVersionInfo" class="version">FECHA RELEASE: {{ appVersionInfo.build.time | date: 'dd/MM/yyyy'}}</p>
    <p *ngIf="appVersionInfo" class="version">VERSION: {{ appVersionInfo.build.version }}</p>
  </mat-dialog-content>
</div>
